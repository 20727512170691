<template>
  <div>
    <v-card class="mt-5 mb-5">
      <v-card-text>
        <div class="text-center">
          <p class="text-lg-h4 text-md-h5 text-sm-subtitle-1 font-weight-black">
            LOGIN
          </p>
        </div>
        <v-form
          v-model="valid"
          lazy-validation
          ref="creditLoginForm"
        >
          <form @keyup.enter="Login">

            <v-alert v-if="notify" :type="alertType">
              {{ notification }}
            </v-alert>
            <v-select 
              item-text="store_name"
              item-value="store_id"
              v-model="store"
              :items="stores"
              label="Select Store Branch"
              :rules="[rules.required]"
              required
              outlined
            />
            <v-text-field 
              v-model="userName"
              type="text"
              label="User Name"
              clearable
              :rules="[rules.required]"
              clear-icon="mdi-close-circle"
              prepend-inner-icon="mdi-account-outline"
              outlined
            />
            <v-text-field 
              v-model="password"
              :type="show ? 'text' : 'password'"
              prepend-inner-icon="mdi-lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              label="Password"
              clearable
              :rules="[rules.required]"
              clear-icon="mdi-close-circle"
              @click:append="show = !show"
              outlined
            />
          </form>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          x-large 
          color='blue lighten-1'
         @click='Login' 
         class="white--text"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import CreditServices from  '@/services/CreditServices'

export default {
  name: 'CreditLogin',
  data: () => ({
    store: '',
    stores: [],
    rules: {
      required: value  => !!value || 'Required'
    },
    userName: '',
    password: '',
    show: false,
    notify: false,
    notification: '',
    valid: true,
    alertType: 'success',
  }),
  mounted () {
    this.setStores()
  },
  methods: {
    async setStores() {
      try {
        const result = await CreditServices.GetStores()
        if (result.data.success) {
          this.stores = result.data.result
        } else {
          console.log(result.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async Login() {
     try {

      if (this.$refs.creditLoginForm.validate()) {
        const result = await CreditServices.CreditLogin({
          userName: this.userName,
          password: this.password,
          storeID: this.store
        })
        if (result.data.success){
          this.notify = true
          this.notification = result.data.message
          this.$store.dispatch('setCreditToken', this.userName)
          this.$store.dispatch('setCreditBranch',  this.store)
          this.alertType = 'success'
          this.$emit('checkLoggIn')
        } else {
          this.notify = true
          this.alertType = 'error'
          this.notification = result.data.message
        }
      } else {
        console.log('please validate form')
      }
    } catch (error) {
        console.log(error)
        this.notify = true
        this.notification = error
     }
    }
  }

}
</script>