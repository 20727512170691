<template>
<v-container color='blue lighten-1'>
  <v-row>
    <v-col cols=12>
      <v-card max-width="800"
        class="mx-auto"
        v-if="!iserror"
      >
        <v-card-text class="confirmtitle"><h2 class="text-center mt-5 mb-5">{{ message }}</h2></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-0 mt-5" color="blue lighten-1" elevation="3" x-large dense dark @click='login'>
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card max-width="800" class="mx-auto" v-if="iserror">
        <v-card-text class="confirmtitle">{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-0 mt-5" color="blue lighten-1" elevation="3" x-large dense dark @click='login'>
            Home
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import AuthService from '@/services/AuthenticationService'
export default {
  data () {
    return {
      memberNo: this.$route.params.memberNo,
      branch: this.$route.params.branch,
      iserror: false,
      message: ''
    }
  },
  mounted () {
    this.ConfirmAccount()
  },
  methods: {
    async ConfirmAccount () {
      try {
        const response = await AuthService.ActiveMemberLogin({
          memberNo: this.$route.params.memberNo,
          branch: this.$route.params.branch,
        })
        if (response.status === 200) {
          console.log(response.data)
          this.message = response.data.Message
        } else {
          this.iserror = true
          this.message = response.data.error
        }
      } catch (error) {
        console.log(error)
      }
    },
    login () {
      this.$router.push({name: 'login'})
    }
  }
}
</script>
<style scoped>

</style>
