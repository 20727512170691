<template>
  <v-container color="blue lighten-1" class="text-center">
    <v-card class="justify-center mb-4 pt-4" id="lateral">
      <v-card-text>
        <div>
          <p class="text-lg-h4 text-xl-h5 text-md-h6 text-sm-subtitle-1 font-weight-black">
            CEMIMPCO Credit Limit System
          </p>
        </div>
        <v-row dense>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <div v-if="isLoggedIn">
              <CreditSearch />
            </div>
            <div v-else> 
              <CreditLogin @checkLoggIn="CheckLoggedIn" />
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-card-text>
      <v-fab-transition v-if="isLoggedIn">
        <v-btn
          color="primary"
          fab
          small
          dark
          top
          right
          class="v-btn--example"
          @click="CreditLogout"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
  </v-container>
</template>
<script>
import AuthService from '@/services/AuthenticationService'
import store from '@/store/store'
import CreditLogin from '@/components/creditLimit/components/Login.vue'
import CreditSearch from '@/components/creditLimit/components/CreditSearch.vue'

export default {
  name: 'CreditHome',
  components: {
    CreditLogin,
    CreditSearch,
  },
  data: () => ({
    isLoggedIn: false,
  }),
  mounted() {
    this.setLoggedUser()
  },
  methods: {
    setLoggedUser() {
      this.isLoggedIn = this.$store.state.isCreditLoggedIn
    },
    CheckLoggedIn() {
     this.isLoggedIn = this.$store.state.isCreditLoggedIn
    },
    CreditLogout() {
      if (confirm('Are you sure you want to log out?')) {
        this.$store.dispatch('setCreditToken', null)
        this.$store.dispatch('setCreditBranch',  null)
        this.CheckLoggedIn()
      }
    }
  }
}
</script>
<style>
#lateral .v-btn--example {
    top: 10px;
    right: 20px;
    position: absolute;
    margin: 0 0 16px 16px;
  }
</style>