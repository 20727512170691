import Api from '@/services/Api'

export default {
  GetStores () {
    return Api().get('api/credit/stores')
  },
  CreditLogin (credentials) {
    return Api().post('api/credit/login', credentials)
  },
  FetchMembers () {
    return Api().get('api/credit/fetch/members')
  },
  SearchMember (credential) {
    return Api().post('api/credit/search/member', credential)
  },
  SendOtp (credentials) {
    return Api().post('api/credit/send/message/otp',  credentials)
  },
  CheckOtp (credentials) {
    return Api().post('api/credit/check/expired/otp', credentials)
  },
  VerifyOtp (credentials) {
    return Api().post('api/credit/verify/otp', credentials)
  },
  StoreBranchName (credential) {
    return Api().get(`api/credit/store/branch/name/${credential}`)
  },
  StoreCredit (credentials) {
    return Api().post('api/credit/store/member/credit', credentials)
  },
  StoreCreditHistory (memberNo) {
    return Api().get(`api/credit/store/${memberNo}/history`)
  }
}