<template>
<v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
        <v-card>
            <v-card-title class="blue lighten-1 justify-center text-h4 white--text mb-10 pt-5 pb-5">
                <strong>Reset Password</strong>
            </v-card-title>
            <v-card-text v-if="!isSuccess">
                <v-form ref='ForgetPassword' v-model='valid' lazy-validation>
                    <form @keyup.enter='ResetPassword'>
                        <v-row>
                            <v-col cols='12' v-if="iserror">
                                <v-alert
                                    color="blue lighten-1"
                                    text
                                    type="error"
                                >{{ errorMessage }}</v-alert>
                            </v-col>
                            <!-- <v-col cols="12" class="mt-4">
                              <v-select item-text="text" item-value="value" v-model="selectedBranch" :items="branch" label="Select Branch"
                                :rules="[rules.required]" required>
                              </v-select>
                            </v-col> -->
                            <v-col cols='12' class="mb-4">
                                <v-text-field label='Email Address' v-model='emailaddress' :rules="[rules.required,rules.email]" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn  @click="BackToHome" class="justify-center pt-5 pb-5 pl-5 pr-5 mb-5 white--text" color="blue lighten-1">
                              Back</v-btn>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col>
                            <v-btn  :disabled='!valid' @click='ResetPassword' class="justify-center pt-5 pb-5 pl-5 pr-5 mb-5 white--text"
                              color="blue lighten-1">Reset Password</v-btn>
                          </v-col>
                        </v-row>
                    </form>
                </v-form>
            </v-card-text>
            <v-card-text v-if="isSuccess">
              <v-row>
                <v-col cols='12'>
                  <v-alert
                      color="green"
                      text
                      outlined
                      type="success"
                  >{{ Message }}</v-alert>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
    </div>
</v-dialog>
</template>
<script>
import AuthService from '@/services/AuthenticationService'
export default {
  data: () => ({
    dialog: true,
    valid: true,
    isSuccess: false,
    branch: [],
    selectedBranch: 1,
    iserror: false,
    emailaddress: '',
    errorMessage: '',
    Message: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 8) || 'Min 8 characters',
      minMember: v => (v && v.length >= 6) || 'Min 6 characters',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    }
  }),
  mounted() {
    this.setBranches()
  },
  methods: {
    setBranches() {
      this.branch = JSON.parse(process.env.VUE_APP_BRANCH_SELECT)
    },
    BackToHome () {
      this.$router.push({ path: 'home' })
    },
    async ResetPassword () {
      if (this.emailaddress.length > 0) {
        const email = await AuthService.checkUserEmail({email: this.emailaddress, branch: this.selectedBranch})
        if (email) {
          console.log(email.data.value)
          if (email.data.value === 1) {
            const response = await AuthService.resetPasswordEmail({
              email: this.emailaddress,
              branch: this.selectedBranch
            })
            if (response.status === 200) {
              console.log(response.data)
              this.isSuccess = true
              this.Message = response.data.Message
            } else {
              this.iserror = true
              this.errorMessage = response.data.error
            }
          } else {
            this.iserror = true
            this.errorMessage = 'Email address does not exist.'
          }
        } else {
          this.iserror = true
          this.errorMessage = 'Server did not response'
        }
      } else {
        this.iserror = true
        this.errorMessage = 'Email address is required'
      }
    }
  },
  asyncComputed: {
    async checkEmail () {
      if (this.emailaddress.length > 0) {
        const email = await AuthService.checkUserEmail({email: this.emailaddress, branch: this.selectedBranch})
        if (email) {
          if (email.data.value === 1) {
            return true
          } else {
            return 'Email address does not exist'
          }
        } else {
          return 'Server did not response'
        }
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped>
</style>
