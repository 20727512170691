<template>
  <v-container class="pb-10">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" xl="12" lg="12">
        <v-card outlined>
          <v-card-title class='cardtitle' color='blue lighten-1'>
            <h2>STORE CREDIT HISTORY</h2>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon outlined color="white--text" dark  class="mr-3" v-on="on" @click="goHome">
                  <v-icon>mdi-arrow-left-circle</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class='pt-8'>
            <v-row>
              <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                <v-text-field class='biggerfont' v-model="member_no" readonly dense outlined label="Member No."></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                <v-text-field class='biggerfont' v-model="member_name" readonly dense outlined label="Member Name:"></v-text-field>
              </v-col>
              <v-col sm='12' cols="12" md="12" xl="12" lg="12">
                <v-card-title class="pt-4 pb-4 cardtitle">
                  <v-row>
                    <v-col sm='12' cols="12" md="6" xl="6" lg="6">
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="6" xl="6" lg="6" class="hidden-sm-and-down">
                      <h4 class="float-right">Balance: {{ balance }}</h4>
                    </v-col>
                    <v-col cols="12" sm="12" class="hidden-md-and-up">
                      <h4>Balance: {{ balance }}</h4>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-col>
              <v-col cols="12" sm="12" md="12" xl="12" lg="12">
                <div>
                  <v-data-table :headers="headers" :items="credits" item-key="index" class="elevation-1">
                    <template v-for="header in headers.filter((header) =>
                                    header.hasOwnProperty('formatter')
                                )" v-slot:[`item.${header.value}`]="{ header, value }">
                      {{ header.formatter(value) }}
                    </template>
                  </v-data-table>
                  <div class="table-footer-prepend d-flex pl-2 align-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined color="primary" class="mr-3" v-on="on" @click="goHome">
                          <v-icon>mdi-arrow-left-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Back</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CreditService from '@/services/CreditServices'
import MemberService from '@/services/MembersService'
import moment from 'moment'
import numeral from 'numeral'
export default {
  data () {
    return {
      member_no: '',
      member_name: '',
      credits: [],
      dateFormat: 'MM/DD/YYYY',
      balance: 0,
      branch: '',
      creditLimit: 0,
    }
  },
  mounted () {
    this.branch = this.$store.state.branch
    this.getMembersProfile()
    this.getCreditHistory()
 
  },
  methods: {
    goHome () {
      this.$router.push({ path: `/home` })
    },
    formatExample (value) {
      // Just an simple example, you can do anything here
      return numeral(value).format('0,0.00')
    },
    async getMembersProfile () {
      const member = await MemberService.GetMemberProfile({
        memberno: this.$store.state.user.member_no,
        branch: this.$store.state.branch
      })
      if (member.status === 200) {
        if (member) {
          this.member_no = member.data.member.member_no
          this.member_name = member.data.member.member_name
          const creditLimit = member.data.member.credit_limit
          const creditAvailed = member.data.member.credit_availed
          const balance = parseFloat((creditLimit - creditAvailed), 2)
          this.creditLimit = parseFloat(creditLimit, 2)
          this.balance = this.formatExample(parseFloat(balance, 2))
        }
      } else {
        console.log(member.status)
      }
    },
    async getCreditHistory () {
      try {
        const result = await CreditService.StoreCreditHistory(this.$store.state.user.member_no)
        if (result.data.success) {
          let credits = []
          result.data.data.forEach((value, index) => {
            credits = {                                                         
              row: index + 1,
              date_availed: value.date_availed,
              auth_refno: value.auth_refno,
              credit: value.amount,
              payment: value.amount_paid,
              balance: value.running_balance,
            }
            this.credits.push(credits)
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'Transaction Date',
          align: 'start',
          sortable: true,
          value: 'date_availed',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Reference',
          sortable: false,
          value: 'auth_refno'
        },
        {
          text: 'Credit',
          sortable: false,
          value: 'credit',
          align: 'right',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        },
        {
          text: 'Payment',
          sortable: false,
          value: 'payment',
          align: 'right',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        },
        {
          text: 'Balance',
          sortable: false,
          value: 'balance',
          align: 'right',
          formatter: (x) => (x ? this.formatExample(x) : '0.00')
        }
      ]
    }
  }
}

</script>
<style scoped>

.cardtitle {
    color: white;
    background-color: #42A5F5;
    padding: 30px 40px 30px 40px;

}
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}

</style>
