<template>
    <div>
        <v-toolbar fixed class="blue lighten-1" dense dark max-height="140px" height="140px">
            <v-toolbar-title class="text-sm-body-1 text-md-title text-lg-h6 text-xl-h5 font-weight-black ml-5 mr-5"
                @click='gohome'>
                <v-img :lazy-src="require('../assets/cemimpco-logo.jpg')" max-height="130" max-width="141"
                    :src="require('../assets/cemimpco-logo.jpg')" class="rounded-lg"></v-img>
            </v-toolbar-title>
           
    
            <v-row dense>
                <v-col cols="12">
                    <p class="text-sm-body-1 text-md-title text-lg-h4 text-xl-h3">
                        Cebu Mitsumi Multi-Purpose Cooperative
                    </p>
                </v-col>
                
                <v-col cols="12" v-if="$store.state.isCreditPage">
                    <p v-if="$store.state.isCreditLoggedIn" class="text-xs-caption text-sm-body-2 text-md-body-1 text-lg-h6">Store Branch: {{ storeName }} </p>
                    <!-- <h4>{{ branch_name }}</h4> -->
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="!$store.state.isCreditPage">
                <div class="text-center hidden-sm-and-down">
                    <v-menu offset-y v-if="$store.state.isUserLoggedIn">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue lighten-2" elevation="5" dark v-bind="attrs" v-on="on"
                                class="ma-8 pa-5 font-weight-black">
                                {{ $store.state.user.name }}
                            </v-btn>
                        </template>
                        <v-list color="blue lighten-2">
                            <v-list-item>
                                <v-list-item-title class='menuitem'>
                                    <v-btn block dense color="blue lighten-2" @click="changepassword" dark>
                                        Change Password
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title class='menuitem'>
                                    <v-btn block dense color="blue lighten-2" @click="logout" dark>
                                        Log-Out
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div class="text-center hidden-md-and-up">
                    <v-menu offset-y v-if="$store.state.isUserLoggedIn">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue lighten-2" dark v-bind="attrs" v-on="on" class="profilebtn">
                                <v-icon>
                                    mdi-menu
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list color="blue lighten-2">
                            <v-list-item>
                                <v-list-item-title class='menuitem'>
                                    <v-btn block dense color="blue lighten-2" @click="changepassword" dark>
                                        Change Password
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title class='menuitem'>
                                    <v-btn block dense color="blue lighten-2" @click="logout" dark>
                                        Log-Out
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-toolbar-items>
        </v-toolbar>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card :loading="loading">
                    <template slot="progress">
                        <v-overlay absolute class="d-flex flex-column text-center">
                            <div>
                                <v-progress-circular size="90" color="blue lighten-1" width="2" indeterminate>
                                    <span class="white--text">Loading</span>
                                </v-progress-circular>
                            </div>
                        </v-overlay>
                    </template>
                    <v-card-title class="text-h6 blue lighten-1 white--text mb-8">
                        Change Password
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = !dialog">
                            <v-icon color="white">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="changePasswordForm" v-model='valid' lazy-validation>
                            <v-text-field placeholder="Current Password" @click:append="showCurrPass = !showCurrPass"
                                :append-icon="showCurrPass ? 'mdi-eye':'mdi-eye-off'"
                                :type="showCurrPass ? 'text' : 'password'" v-model="currPassword"
                                label="Current Password" :rules="[rules.required]" required></v-text-field>
                            <v-text-field placeholder="New Password" @click:append="showNewPass = !showNewPass"
                                :append-icon="showNewPass ? 'mdi-eye':'mdi-eye-off'"
                                :type="showNewPass ? 'text' : 'password'" v-model="newPassword" label="New Password"
                                :rules="[rules.required,rules.min]" required></v-text-field>
                            <v-text-field placeholder="Confirm Password"
                                @click:append="showConfirmPass = !showConfirmPass"
                                :append-icon="showConfirmPass ? 'mdi-eye':'mdi-eye-off'"
                                :type="showConfirmPass ? 'text' : 'password'" v-model="confirmPassword"
                                label="Confirm Password" :rules="[rules.required, passwordMatch]" required>
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue lighten-1" class="white--text" @click="ChangePass">
                            Change Password
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-snackbar v-model="snackbar" :timeout="timeout" :color="responseColor">
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white--text" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import userService from '@/services/AuthenticationService'
import CreditServices from '@/services/CreditServices';
import { EventBus } from '@/event-bus.js'

export default {
  data: () => ({
        items: [
            { title: 'Change Password', click: 'changepassword' },
            { title: 'Log-Out', click: 'logout' }
        ],
    dialog: false,
    showCurrPass: false,
    showNewPass: false,
    showConfirmPass: false,
    snackbar: false,
    timeout: 2000,
    currPassword: '',
    newPassword: '',
    confirmPassword: '',
    loading: false,
    valid: true,
    responseColor: 'success',
    message: '',
    branch_name: '',
    isCreditLogin: false,
    storeName: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 8) || 'Min 8 characters'
    }
  }),
  computed: {
    passwordMatch () {
      if (this.newPassword === this.confirmPassword) {
        return true
      } else {
        return 'Password must match'
      }
    }
  },
  created () {
      EventBus.$on('update-branch', () => {
        console.log('test')
         this.setBranchName(this.$store.state.branch)
      })
  },
  mounted () {
    this.setStoreBranchName(this.$store.state.creditBranch)
    this.setBranchName(this.$store.state.branch)
  },    
  methods: {
    async setStoreBranchName(branch) {
        try {
            const response = await CreditServices.StoreBranchName(branch)
            if (response.data.success) {
                this.storeName = response.data.branchName
            } else {
                this.storeName = ''
                this.isCreditLogin = false
            }
        } catch (error) {
            console.log(error)
        }
    },
    async setBranchName(branch) {
       try {
           const result = await userService.GetBranchName({ branch: branch })
           if (result.status === 200) {
            this.branch_name = result.data
           } else {
            this.branch_name = '' 
           }
       } catch (error) {
           console.log(error)
       }
    },  
    async ChangePass () {
      try {
        if (this.$refs.changePasswordForm.validate()) {
          this.loading = true
          const response = await userService.userPassword({
            memberno: this.$store.state.user.member_no,
            currpassword: this.currPassword,
            newpassword: this.newPassword,
            branch: this.$store.state.branch,
          })
          if (response.status === 200) {
            this.loading = false
            this.dialog = false
            this.snackbar = true
            this.response = 'success'
            this.message = response.data.message
          } else {
            this.loading = false
            this.snackbar = true
            this.message = response.data.error
          }
        }
      } catch (error) {
        this.loading = false
        this.snackbar = true
        this.responseColor = 'red accent-2'
        this.message = error.response.data.error
      }
    },
    changepassword () {
      this.dialog = true
      this.currPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
    },
    logout () {
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      this.$store.dispatch('setBranch', null)
      this.setBranchName('')
      this.$router.push({name: 'login'})
    },
    gohome () {
        this.$router.push({ name: 'home' }).catch(() => { })
    }
  }
}
</script>
<style scoped>
.profilebtn {
    margin-top: 25px;
    margin-right: 10px;
    font-size: 20px;
}
</style>
