import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'
import Confirm from '@/components/Confirmation'
import DepositProfile from '@/components/DepositProfile'
import LoanDetail from '@/components/loanComponents/LoanDetails'
import AsyncComputed from 'vue-async-computed'
import ForgetPassword from '@/components/ForgetPassword'
import ResetPassword from '@/components/ResetPassword'
import Dividend from '@/components/Dividend'
import LoanApplication from '@/components/loanComponents/LoanApplication'
import LoanApply from '@/components/loanComponents/LoanApply'
import store from '@/store/store'
import CreditPage from '@/components/creditLimit/Home'
import CreditHistory from '@/components/CreditHistory'

Vue.use(Router)
Vue.use(AsyncComputed)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        IsLoginPage: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        IsLoginPage: true
      }
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/confirm/:memberNo/:branch',
      name: 'confirm',
      component: Confirm
    },
    {
      path: '/deposit/:deposit_type',
      name: 'depositprofile',
      component: DepositProfile,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/loan/:loanid',
      name: 'loandetail',
      component: LoanDetail,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/credit/history/:memberNo',
      name: 'creditHistory',
      component: CreditHistory,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/forgetpassword',
      name: 'forgetpassword',
      component: ForgetPassword
    },
    {
      path: '/resetpassword/:branch/:email/:token',
      name: 'resetpassword',
      component: ResetPassword
    },
    {
      path: '/pdf/:memberno',
      name: 'pdf',
      component: Dividend,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/loanapplication/:memberno',
      name: 'loanapplication',
      component: LoanApplication,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/loan/application/:memberno',
      name: 'loanapply',
      component: LoanApply,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/creditLimit',
      name: 'creditHome',
      component: CreditPage,
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('setCreditPage', false)
  if (to.matched.some(record => record.meta.isAuth)) {
    if (store.state.isUserLoggedIn) {
      next()
    } else {
      router.replace('/login')
    }
  } else if (to.matched.some(record => record.meta.IsLoginPage)) {
    if (store.state.isUserLoggedIn) {
      router.replace('/home')
    } else {
      next()
    }
  } else {
    if (to.matched.some(record => record.name = 'creditHome')) {
      store.dispatch('setCreditPage', true)
      next()
    } else {
      store.dispatch('setCreditPage', false)
      next()
    }
  }
})

export default router
