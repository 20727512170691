<template>
<v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
        <v-card>
            <v-card-title class="blue lighten-1 justify-center text-h4 white--text mb-10 pt-5 pb-5">
                <strong>Reset Password</strong>
            </v-card-title>
            <v-card-text v-if="!isSuccess">
                <v-form ref='ForgetPassword' v-model='valid' lazy-validation>
                    <form @keyup.enter='ResetPassword'>
                        <v-row>
                            <v-col cols='12' v-if="iserror">
                                <v-alert
                                    color="blue lighten-1"
                                    text
                                    type="error"
                                >{{ errorMessage }}</v-alert>
                            </v-col>
                            <v-col cols='12' class="mt-4">
                                <v-text-field label='New Password' v-model='password' :rules="[rules.required, rules.min, rules.max]" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"  :type="show1 ? 'text' : 'password'" name='input-10-1' hint='At least 8 characters' counter @click:append='show1 = !show1'></v-text-field>
                            </v-col>
                            <v-col cols='12' class="mb-4">
                                <v-text-field label='Confirm-New Password' v-model='confirm_password' :rules="[rules.required, rules.min, rules.max, passwordMatch]" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"  :type="show2 ? 'text' : 'password'" name='input-10-1' hint='At least 8 characters' counter @click:append='show2 = !show2'></v-text-field>
                            </v-col>
                            <v-col cols='12'>
                                <v-btn large :disabled='!valid' @click='ResetPassword' class="justify-center pt-5 pb-5 pl-5 pr-5 mb-5 white--text" color="blue lighten-1">Reset Password</v-btn>
                            </v-col>
                        </v-row>
                    </form>
                </v-form>
            </v-card-text>
            <v-card-text v-if="isSuccess">
              <v-row>
                <v-col cols='12'>
                  <v-alert
                      color="green"
                      text
                      outlined
                      type="success"
                  >{{ Message }}</v-alert>
                </v-col>
                <v-col cols='12'>
                  <v-btn large @click='BackToLogin' class="justify-center pt-5 pb-5 pl-5 pr-5 mb-5 white--text" color="blue lighten-1">Login</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
    </div>
</v-dialog>
</template>
<script>
import AuthService from '@/services/AuthenticationService'
export default {
  data () {
    return {
      email: this.$route.params.email,
      token: this.$route.params.token,
      branch: this.$route.params.branch,
      dialog: true,
      valid: true,
      isSuccess: false,
      iserror: false,
      emailaddress: '',
      errorMessage: '',
      password: '',
      confirm_password: '',
      Message: '',
      show1: false,
      show2: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => (v && v.length >= 8) || 'Min 8 characters',
        max: v => (v && v.length <= 20) || 'Password must not exceed 20 characters',
        minMember: v => (v && v.length >= 6) || 'Min 6 characters',
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      }
    }
  },
  methods: {
    async ResetPassword () {
      try {
        const response = await AuthService.resetPassword({
          password: this.password,
          email: this.email,
          token: this.token,
          branch: this.branch,
        })
        if (response.status === 200) {
          this.isSuccess = true
          this.Message = response.data.Message
        } else {
          this.iserror = true
          this.errorMessage = response.data.error
        }
      } catch (error) {
        console.log(error)
      }
    },
    BackToLogin () {
      this.$router.push({name: 'login'})
    }
  },
  computed: {
    passwordMatch () {
      if (this.password === this.confirm_password) {
        return true
      } else {
        return 'Password did not match'
      }
    }
  }
}
</script>
<style scoped>
</style>
