import Api from '@/services/Api'

export default {
  GetMemberProfile (credentials) {
    return Api().post('api/member/profile', credentials)
  },
  GetMemberDeposits (credentials) {
    return Api().post('api/member/deposit', credentials)
  },
  GetMemberLoans (credentials) {
    return Api().post('api/member/loans' , credentials)
  },
  GetMemberTimeDeposits (credentials) {
    return Api().post('api/member/timedepo' , credentials)
  },
  GetMemberDepositHeader (credentials) {
    return Api().post('api/deposit/header', credentials)
  },
  GetMemberDepositDetail (credentials) {
    return Api().post('api/deposit/detail', credentials)
  },
  GetMemberLoanDetail (credentials) {
    return Api().post('api/loan/details', credentials)
  },
  GetMemberLoanHeader (credentials) {
    return Api().post('api/loan/header', credentials)
  },
  GetLoanApplicationPassword (credentials) {
    return Api().get('api/member/loanapplication/' + credentials)
  },
  GetAccessLoanApp (memberNo) {
    return Api().get(`api/member/loan/application/check/${memberNo}`)
  },
  GetDividendSetting () {
    return Api().get('api/member/dividendsetting/check')
  },
  ViewDividend (credential) {
    return Api().post('api/member/dividend/view', credential)
  }
}
