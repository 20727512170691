/* eslint-disable vue/require-v-for-key */
<template>
  <v-container class="pb-10 mt-8">
    <v-row no-gutters>
      <!-- <v-col sm="12" xs=12 md="1" lg="1" xl="1" cols="12"></v-col> -->
      <v-col sm="12" xs="12" md="12" lg="12" xl="12" cols="12">
        <v-card outlined class="justify-center">
          <v-card-title class='cardtitle' color='blue lighten-1'>
            <span class="text-body-1 text-sm-subtitle-1 text-lg-h5 text-md-h6 font-weight-bold">CEBU MITSUMI COOPERATIVE MEMBER
              PROFILE</span>
            <span class="text-body-1 d-flex d-sm-none">CEBU MITSUMI COOPERATIVE MEMBER
              PROFILE</span>
          </v-card-title>
          <v-card-text class='pt-8'>
            <v-row no-gutters dense>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-row class="mr-2">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field 
                      class='text-sm-body-1 text-md-title text-lg-title text-xl-title' 
                      v-model="member_no"
                      readonly 
                      dense 
                      outlined 
                      label="Member No.">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field 
                      class='text-sm-body-1 text-md-title text-lg-title text-xl-title' 
                      v-model="member_name"
                      readonly 
                      dense 
                      outlined 
                      label="Member Name:">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field 
                      class='text-sm-body-1 text-md-title text-lg-title text-xl-title' 
                      type="email"
                      v-model="email" 
                      readonly 
                      dense 
                      outlined 
                      label="Email Address">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field 
                      class='text-sm-body-1 text-md-title text-lg-title text-xl-title' 
                      v-model="telno"
                      readonly 
                      dense 
                      outlined 
                      label="Contact No.">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="my-8">
                <v-row class="mx-1">
                  <!-- <v-col cols="12" class="mb-5" v-show="allow_dividend">
<v-btn color='red accent-2' class="py-10" dark dense block @click="ViewDividend">
View Dividend
                    </v-btn>
                  </v-col> -->
                  <v-col cols="12" xl="4" lg="4" md="2" sm="12" xs="12">

                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="8" sm="12" xs="12">
                    
                    <div v-if="isUnderConstruction" class="text-center " >
                      <v-alert
                        class="text-center"
                        elevation="2"
                        color="blue lighten-1"
                        dark
                      >
                        {{ loanapp_caption }}
                      </v-alert>
                    </div>

                    <v-btn v-else color='blue lighten-1' x-large block dark dense @click="LoanApplication">
                      Loan Application
                    </v-btn>
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="2" sm="12" xs="12">
                  
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters class="pt-8">
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="cardtitledeposit" color="blue lighten-2">
             <h4>STORE CREDIT</h4>
          </v-card-title>
          <v-card-text class="pt-8">
            <v-row>
              <v-col cols="12" sm="12" md="3" xl="3" lg="3">
                <v-text-field v-model="creditLimit" outlined label="Credit limit" dense readonly />
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="3" lg="3">
                <v-text-field v-model="creditAvailed" outlined label="Credit Availed" dense readonly />
              </v-col>
              <v-col cols="12" sm="12" md="3" xl="3" lg="3">
                <v-text-field v-model="creditBalance" outlined label="Credit Balance" dense readonly />
              </v-col>
              <v-col cols="12" sm="12" xl="3" lg="3" md="3">
                <div class="d-flex justify-end pr-5">
                  <v-btn  color='blue lighten-1' dark dense @click="viewCreditHistory">
                    Credit History
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters class="pt-8">
        <v-col sm="12" xs="12" md="12" lg="12" xl="12" cols="12">
        <v-card outlined>
          <v-card-title class='cardtitledeposit' color='blue lighten-1'>
            <h4>DEPOSITS</h4>
          </v-card-title>
          <v-card-text class='pt-8'>
            <div v-for="deposit in deposits" :key="deposit.index">
              <v-row>
                <v-col xl="6" lg="6" md="6" class="hidden-sm-and-down">
                  <v-subheader class='text-sm-body-1 text-md-title text-lg-title text-xl-title'>{{ deposit.description
                  }}</v-subheader>
                </v-col>
                <v-col xl="3" lg="3" md="3" class="hidden-sm-and-down">
                  <v-text-field class='text-sm-body-1 text-md-title text-lg-title text-xl-title' readonly dense solo reverse
                    :value="formatExample(deposit.balance)">
                  </v-text-field>
                </v-col>
                <v-col cols="10" xs="10" sm="10" class="hidden-md-and-up">
                  <v-text-field class='text-sm-body-1 text-md-title text-lg-title text-xl-title' readonly dense outlined
                    :value="formatExample(deposit.balance)" :label="deposit.description">
                  </v-text-field>
                </v-col>
                <v-col xl="3" lg="3" md="3" class="hidden-sm-and-down">
                  <div class="d-flex justify-end pr-5">
                    <v-btn   color='blue lighten-1' dark dense @click="ViewDepositDetail(deposit.deposittype)">
                      View Ledger
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="2" xs="2" sm="2" class="hidden-md-and-up">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex justify-end pr-5"  v-on="on" v-bind="attrs" >
                          <v-btn color='blue lighten-1' dark dense @click="ViewDepositDetail(deposit.deposittype)">
                            ...
                          </v-btn>
                        </div>
                      </template>
                      <span>View Ledger</span>
                    </v-tooltip>
                
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<v-row no-gutters class="pt-8">
      <v-col sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card outlined>
          <v-card-title class='cardtitledeposit' color='blue lighten-1'>
            <h4>TIME DEPOSITS</h4>
          </v-card-title>
          <v-card-text class='pt-8'>
            <div>
              <v-data-table :headers="headersTimeDepo" :items="timedeposits" item-key="index" class="elevation-1"
                hide-default-footer>
                <template v-for="header in headersTimeDepo.filter((header) =>
                    header.hasOwnProperty('formatter')
                )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<v-row no-gutters class="pt-8">
      <v-col sm="12" md="12" lg="12" xl="12" cols="12">
        <v-card outlined>
          <v-card-title class='cardtitledeposit' color='blue lighten-1'>
            <h4>LOANS</h4>
          </v-card-title>
          <v-card-text class='pt-8'>
            <div>
              <v-data-table :headers="headers" :items="loans" item-key="index" class="elevation-1" hide-default-footer>
                <template v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                )" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>
                <template v-slot:item.controls="props" >
                  <div class="d-flex justify-end pr-5">
                    <v-btn dark color="blue lighten-1" @click="ViewLoanLedger(props.item.loan_id)">
                      View Ledger
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- dialog for password -->
<v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="300" @keydown.esc="closedialog">
        <v-card>
          <v-card-title>
            <span>Please Enter Password:</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="okpassword">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field label="Password" v-model="apppassword" type="password">
                    </v-text-field>
                  </v-col>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue lighten-1" dark text @click="closedialog">Close</v-btn>
            <v-btn color="blue lighten-1" dark text @click="okpassword">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import MemberService from '@/services/MembersService'
import moment from 'moment'
import numeral from 'numeral'
export default {
  data () {
    return {
      member_no: '',
      member_name: '',
      dob: '',
      email: '',
      telno: '',
      deposits: [],
      loans: [],
      dateFormat: 'MM/DD/YYYY',
      timedeposits: [],
      dialog: false,
      apppassword: '',
      loanapp_caption: '',
      allow_access: false,
      allow_dividend: true,
      isUnderConstruction: false,
      creditLimit: 0,
      creditAvailed: 0,
      creditBalance: 0,
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'Loan ID',
          align: 'start',
          sortable: false,
          value: 'loan_id'
        },
        {
          text: 'Loan Type',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Loan Date',
          sortable: false,
          value: 'loan_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Loan Amount',
          sortable: false,
          value: 'loan_amt',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Payments',
          sortable: false,
          value: 'payments',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Interest',
          sortable: false,
          value: 'interest',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Balance',
          sortable: false,
          value: 'balance',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: '',
          sortable: false,
          value: 'controls'
        }
      ]
    },
    headersTimeDepo () {
      return [
        {
          text: 'Date',
          sortable: false,
          value: 'dep_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : 0.00)
        },
        {
          text: 'Reference No.',
          sortable: false,
          value: 'jrnref_no'
        },
        {
          text: 'Amount',
          sortable: false,
          value: 'dep_amt',
          formatter: (x) => (x ? this.formatExample(x) : 0.00)
        },
        {
          text: 'Term',
          value: 'term',
          sortable: false
        },
        {
          text: 'Maturity',
          sortable: false,
          value: 'due_date',
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null)
        },
        {
          text: 'Interest Rate',
          sortable: false,
          value: 'int_rate'
        },
        {
          text: 'Certificate No',
          sortable: false,
          value: 'td_certif_no'
        }
      ]
    }
  },
  mounted () {
    this.getMembersProfile()
    this.getMembersDeposits()
    this.getMemberLoans()
    this.getMemberTimeDeposits()
  },
  methods: {
    formatExample (value) {
      return numeral(value).format('0,0.00')
    },
    async getMembersProfile () {
      const member = await MemberService.GetMemberProfile ({
        memberno: this.$store.state.user.member_no, 
        branch: this.$store.state.branch
      })
      if (member.status === 200) {
        if (member) {
          this.member_no = member.data.member.member_no
          this.member_name = member.data.member.member_name
          this.dob = moment(member.data.member.bdate).format('yyyy-MM-DD')
          this.email = member.data.member.email
          this.telno = member.data.member.telno
          this.$store.state.memberMobileNo = member.data.member.telno
          const creditLimit = parseFloat(member.data.member.credit_limit, 2)
          const creditAvailed = parseFloat(member.data.member.credit_availed, 2)
          this.creditLimit = this.formatExample(creditLimit)
          this.creditAvailed = this.formatExample(creditAvailed)
          this.creditBalance =  this.formatExample(creditLimit - creditAvailed)
        }
        const response = await MemberService.GetAccessLoanApp(this.member_no)
          
        if (response.data.response) {
          this.isUnderConstruction = true
          this.loanapp_caption = `LOAN APPLICATION \n (UNDERCONSTRUCTION)`
        } else {
          this.allow_access = response.data.response
          this.isUnderConstruction = false
          this.loanapp_caption = 'LOAN APPLICATION'
        }
        const dividendSetting = await MemberService.GetDividendSetting()
        this.allow_dividend = dividendSetting.data
      } else {
        console.log(member.status)
      }
    },
    async getMembersDeposits () {
      try {
        MemberService.GetMemberDeposits({
          memberno: this.$store.state.user.member_no,
          branch: this.$store.state.branch
        })
        .then((res) => {
          this.deposits = res.data.deposits
        }).catch((err) => {
          console.log(err)
        })
      //   const deposit = await MemberService.GetMemberDeposits(this.$store.state.user.member_no).then(res)
      //   if (deposit.status === 200) {
      //     if (deposit) {
      //       this.deposits = deposit.data.deposits
      //     }
      //   } else {
      //     console.log('No data found!!')
      //   }
      } catch (error) {
        // console.log(error)
      }
    },
    async getMemberLoans () {
      MemberService.GetMemberLoans({
        memberno: this.$store.state.user.member_no,
        branch: this.$store.state.branch
      }).then((res) => {
        this.loans = res.data.loans
      }).catch((error) => {
        console.log(error.response.data.error)
      })
      // try {
      //   const loan = await MemberService.GetMemberLoans(this.$store.state.user.member_no)
      //   if (loan.status === 200) {
      //     if (loan) {
      //       this.loans = loan.data.loans
      //     }
      //   } else if (loan.status === 400) {
      //     console.log('wewe')
      //   }
      // } catch (error) {
      //   console.log(error.response.status)
      // }
    },
    async getMemberTimeDeposits () {
      // try {
      MemberService.GetMemberTimeDeposits({
        memberno: this.$store.state.user.member_no,
        branch: this.$store.state.branch
      }).then(res => {
        this.timedeposits = res.data.timedeposits
      }).catch(error => {
        console.log(error.response.data.error)
      })
      // const timedepo = await MemberService.GetMemberTimeDeposits(this.$store.state.user.member_no)
      // if (timedepo.status === 200) {
      //   if (timedepo) {
      //     this.timedeposits = timedepo.data.timedeposits
      //   }
      // } else {
      //   console.log(timedepo.status)
      // }
      // } catch (error) {
      //   console.log(error)
      // }
    },
    ViewDepositDetail (val) {
      this.$router.push({ path: `/deposit/${val}` })
    },
    ViewLoanLedger (val) {
      this.$router.push({ path: `/loan/${val}` })
    },
    ViewDividend () {
      this.$router.push({ path: `/pdf/${this.member_no}` })
    },
    async LoanApplication () {
      if (this.allow_access) {
        this.dialog = true
      } else {
        this.$router.push({ path: `/loanapplication/${this.member_no}` })
      }
      // const pass = await MemberService.GetLoanApplicationPassword()
      // this.$router.push({ path: `/loanapplication/${this.member_no}` })
    },
    closedialog () {
      this.apppassword = ''
      this.dialog = false
    },
    async okpassword () {
      if (this.apppassword === null || this.apppassword === '') {
        alert('Please Enter Password!')
      } else {
        const pass = await MemberService.GetLoanApplicationPassword(this.apppassword)
        if (pass.data) {
          this.$router.push({ path: `/loanapplication/${this.member_no}` })
          this.dialog = false
        } else {
          alert('Invalid Password')
        }
      }
    },
    async viewCreditHistory () {
      this.$router.push({ path: `/credit/history/${this.member_no}` })    }
  }
}
</script>
<style scoped>
.cardtitle {
    color: white;
    background-color: #42A5F5;

}
.cardtitledeposit {
    color: white;
    background-color: #42A5F5;
}
.biggerfont{
    font-size: 20px;
}
.col{
    padding: 0;
}
.biggerfont-input >>> input{
    font-size: 20px;
}
</style>
