import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {
    token: null,
    creditToken: null,
    memberMobileNo: null,
    user: null,
    branch: null,
    creditBranch: null,
    isUserLoggedIn: false,
    isCreditLoggedIn: false,
    isCreditPage: false,
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      if (token) {
        state.isUserLoggedIn = true
      } else {
        state.isUserLoggedIn = false
      }
    },
    setCreditToken (state, token) {
      state.creditToken = token 
      if (token) {
        state.isCreditLoggedIn = true
      } else {
        state.isCreditLoggedIn = false
      }
    },
    setUser (state, user) {
      state.user = user
    },
    setBranch (state, branch) {
      state.branch = branch
    },
    setCreditBranch (state, branch) {
      state.creditBranch = branch
    },
    setMemberMobileNo (state, mobileNo) {
      state.memberMobileNo = mobileNo
    },
    setCreditPage (state, creditPage) {
      state.isCreditPage = creditPage
    }
  },
  actions: {
    setToken ({commit}, token) {
      commit('setToken', token)
    },
    setCreditToken ({commit} , token) {
      commit('setCreditToken', token)
    },
    setUser ({commit}, user) {
      commit('setUser', user)
    },
    setBranch({commit}, branch) {
      commit('setBranch', branch)
    },
    setCreditBranch({commit}, branch) {
      commit('setCreditBranch', branch)
    },
    setMemberMobileNo({commit}, mobileNo) {
      commit('setMemberMobileNo', mobileNo)
    },
    setCreditPage({commit}, creditPage) {
      commit('setCreditPage', creditPage)
    }
  }  
})
