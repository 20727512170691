<template>
  <div>
    <v-card elevation="6" class="justify-center mb-5" v-if="!otpView">
      <v-card-text>
        <v-row dense no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="memberNo"
              outlined
              label="Search Member No."
              @keyup.enter="searchMember"
              clearable
              dense
              :readonly="otpVerified ? true : false"
              ref="searchInput"
              class="search-member"
            >
              <template v-slot:append-outer>
                <v-btn @click="searchMember" class="white--text hidden-sm-and-down mt-0"  color="blue lighten-1" :disabled="otpVerified ? true : false">
                  <v-icon left dark>
                    mdi-magnify
                  </v-icon>
                  Search
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="hidden-md-and-up">
            <v-btn @click="searchMember" class="white--text" color="blue lighten-1">
              <v-icon left dark>
                mdi-magnify
              </v-icon>
              Search
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="notify">
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
          <v-col cols="12" class="mt-5">
            <v-form v-model="valid" ref="creditForm" lazy-validation>
              <v-row dense no-gutters>
                <v-col cols="5">
                  <p class="font-weight-black text-left">
                    Member Name:
                  </p>
                </v-col>
                <v-col cols="7">
                  <v-text-field dense v-model="memberName" outlined readonly/>
                </v-col>
                <v-col cols="5">
                  <p class="font-weight-black text-left">
                    Mobile No.:
                  </p>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    dense
                    v-model="memberMobileNo"
                    outlined
                    :readonly="otpVerified ? true : false"
                  />
                </v-col>
                <v-col cols="5">
                  <p class="font-weight-black text-left">
                    Available Credit Limit:
                  </p>
                </v-col>
                <v-col cols="7" class="text-left">
                  <v-text-field v-model="creditLimit" type="number" readonly outlined dense />
                </v-col>
                <v-col cols="5">
                  <p class="font-weight-black text-left">
                    Credit Availed:
                  </p>
                </v-col>
                <v-col cols="7" class="text-left">
                  <v-text-field v-model="creditAvailed" type="number" readonly outlined dense />
                </v-col>
                <v-col cols="5">
                  <p class="font-weight-black text-left">
                    Balance: 
                  </p>
                </v-col>
                <v-col cols="7" class="text-left">
                  <v-text-field v-model="creditBalance" type="number" readonly outlined dense />
                </v-col>
                <v-col cols="5">
                  <p class="font-weight-black text-left">
                    Amount to avail:
                  </p>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    ref="inputAvailed"
                    v-model="amountAvailed"
                    type="number"
                    outlined
                    dense
                    :readonly="otpVerified ? true : false"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" v-if="otpVerified">
                  <p class="font-weight-black text-left">
                    Authorization Ref No.:
                  </p>
                </v-col>
                <v-col cols="7" v-if="otpVerified">
                  <v-text-field
                    v-model="referenceNo"
                    outlined
                    dense
                    readonly
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="blue lighten-1"
                        icon
                        dark
                        @click="CopyToClipBoard"
                      >
                        <v-icon dark>
                          mdi-clipboard-check-outline
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          class="white--text mb-5"
          color="blue lighten-1"
          @click="CreditAvailed"
        >
          {{ otpVerified ? 'New Transaction' : 'Submit'  }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card elevation="6" class="justify-center my-3" v-else>
      <v-card-text>
        <OTPView
          @closeView="CloseOTPView"
          @set-reference-no="SetReferenceNo"
          :mobileNo="memberMobileNo"
          :expiredDate="expiredDate"
          :memberNo="memberNo"
          :amount="amountAvailed"
        />
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="5000"
    >
      <p class="text-center text-xl-h4 text-lg-h5 text-md-h6 text-sm-subtitle">{{ snackbarMessage }}</p>
    </v-snackbar>
    <v-dialog v-model="dialogLoading" persistent width="350">
      <v-card color="blue lighten-1">
        <v-card-text color="blue lighten-1">
          <div class="text-center white--text mb-10 pt-5">
            <h3>Saving...</h3>
          </div>
          <div class="text-center mb-5">
            <v-progress-circular class="text-center" :size="80" :width="8" color="white" indeterminate>
            </v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CreditServices from '@/services/CreditServices'
import OTPView from '@/components/creditLimit/components/OTP.vue'

export default {
  name: 'CreditSearch',
  components: {
    OTPView,
  },
  data: () => ({
    search: '',
    isLoading: false,
    member: '',
    members: [],
    memberNo: '',
    memberName: '',
    memberMobileNo: '',
    creditLimit: 0,
    creditAvailed: 0,
    creditBalance: 0,
    amountAvailed: 0,
    notify: false,
    error: '',
    valid: true,
    otp: '',
    expiredDate: '',
    otpView:false,
    otpVerified: false,
    referenceNo: '',
    snackbar: false,
    dialogLoading: false,
    snackbarColor: 'Primary',
    snackbarMessage: '',
    rules: {
      required: value => !!value || `Mobile Phone no is required!`,
      phoneNumber: value => /^(09|\+639)\d{9}$/.test(value) || 'Invalid Phone No.'
    }
  }),
  mounted() {
    this.$refs.searchInput.focus()
    this.FetchMembers()
  },
  methods: {
    async FetchMembers() {
      try {
        const result = await CreditServices.FetchMembers()
        if (result.data.success) {
          this.members = result.data.members
        } else {
          this.members = []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async searchMember() {
      this.otpVerified = false
      try {
        const result = await CreditServices.SearchMember({
          memberNo: this.memberNo
        })
        if (result.data.success) {
          this.memberName = result.data.member[0].member_name
          this.memberMobileNo = result.data.member[0].sms_cpno
          this.creditLimit = result.data.member[0].credit_limit
          this.creditAvailed = result.data.member[0].credit_availed
          this.creditBalance =  (result.data.member[0].credit_limit - result.data.member[0].credit_availed).toFixed(2)
          this.amountAvailed = 0
          this.$refs.inputAvailed.focus()
        } else {
          alert(result.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async CreditAvailed() {
      if (this.otpVerified) {
        this.memberNo = '',
        this.memberName = ''
        this.memberMobileNo = ''
        this.creditLimit = 0
        this.amountAvailed = 0
        this.creditAvailed = 0
        this.creditBalance = 0
        this.referenceNo = ''
        this.$refs.searchInput.focus()
        this.otpVerified = false
        return
      }
      if (this.$refs.creditForm.validate()) {
        if (parseFloat(this.creditBalance,2) < parseFloat(this.amountAvailed,2)) {
          this.notify = true
          this.error = 'Amount Availed must not be greater the Credit Balance'
        } else if (parseFloat(this.amountAvailed, 2) <= 0) {
          this.notify = true
          this.error = 'Please enter availed amount!'
        } else {
          this.dialogLoading = true
          try {
            const response = await CreditServices.StoreCredit({
              memberNo: this.memberNo,
              storeID: this.$store.state.creditBranch,
              amount: this.amountAvailed
            })
            console.log(response.data)
            if (response.data.success) {
              const amount = response.data.amount
              this.dialogLoading = false
              this.otpVerified = true
              this.referenceNo = response.data.referenceNo
              this.creditAvailed = parseFloat(this.creditAvailed, 2) + parseFloat(amount, 2)
              this.creditBalance = parseFloat(this.creditBalance, 2) - parseFloat(amount, 2)
              this.snackbar = true
              this.snackbarColor = 'green accent-2'
              this.snackbarMessage = response.data.message
            } else {
              this.dialogLoading = false
              this.snackbar = true
              this.snackbarColor = 'deep-orange darken-3'
              this.snackbarMessage = response.data.message
            }
            // old version for sending OTP
            // const checkOtp = await CreditServices.CheckOtp({
            //   mobileNo: this.memberMobileNo,
            //   memberNo: this.memberNo
            // })
            // if (checkOtp.data.response.success) {
            //  this.expiredDate = checkOtp.data.response.data
            //   this.notify = false
            //   this.otpView = true
            // } else {
            //    const response = await CreditServices.SendOtp({
            //     number: this.memberMobileNo,
            //     memberNo: this.memberNo
            //   })

            //   if (response.data.success) {
            //     this.expiredDate = 300
            //     this.notify = false
            //     this.otpView = true
            //   } else {
            //     alert(response.data.message)
            //   }

            // }
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    CloseOTPView (value) {
      this.otpView = value
      this.memberName = ''
      this.memberNo = ''
      this.memberMobileNo = ''
      this.amountAvailed = 0
      this.creditLimit = 0
      this.otpVerified = false
    },
    SetReferenceNo (refNo) {
      this.otpView = false
      this.otpVerified = true
      this.referenceNo = refNo.refNo,
      this.creditLimit = this.creditLimit - refNo.amount

    },
    CopyToClipBoard () {
      navigator.clipboard.writeText(this.referenceNo)
      this.snackbar = true
      this.snackbarColor = 'deep-orange darken-3'
      this.snackbarMessage = 'Reference No successfully copy to clipboard'
    }
   }
}
</script>

<style>
.search-member .v-input__control{
  margin-top: 8px !important;
}
</style>