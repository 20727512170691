<template>
  <div>
    <div class="text-xl-h3 text-lg-h4 text-md-h5 text-sm-h6 text-center font-weight-bold my-2">Enter OTP</div> 
    <div
      class="ma-auto position-relative"
      style="max-width: 300px"
    >
      <v-otp-input
        v-model="otp"
        class="mt-5"
        :disabled="loading"
        @finish="onFinish"
      ></v-otp-input>
      <v-overlay absolute :value="loading">
        <v-progress-circular
          indeterminate
          color="blue lighten-1"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <div class="mt-4">

      <p class="text-center" v-if="reSendCountDown > 0">
        Please Wait to re send otp: <strong class="red--text">{{ formatCountDown }}</strong>
      </p>
      <v-btn 
        color="blue lighten-1"
        class="font-weight-bold mb-5 mt-3"
        dark v-else
        @click="reSendOtp"
      >
        Re Send OTP
      </v-btn>
    </div>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="2000"
    >
      {{ message }}
    </v-snackbar>
    <v-fab-transition>
      <v-btn
        color="primary"
        fab
        x-small
        dark
        top
        right
        class="v-btn--example"
        @click="closeOTPView"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import CreditServices from '@/services/CreditServices'
import moment from 'moment'
import 'moment-duration-format'
export default {
  name:'OTP',
  props: {
    mobileNo: {
      type: String,
      required: true
    },
    memberNo: {
      type: String,
      required: true
    },
    expiredDate: {
      type: Number,
      required: false
    },
    amount: {
      type: String,
      required: true
    }
  },  
  data: () => ({
    snackbar: false,
    snackbarColor: 'Primary',
    loading: false,
    otp: '',
    message: '',
    reSendCountDown: 300,
    amountAvailed: 0,
  }),
  mounted() {
    this.amountAvailed = this.amount
    this.reSendCountDown = this.expiredDate
    const stopCount = setInterval(() => {
      this.reSendCountDown -= 1
      if (this.reSendCountDown == 0) clearInterval(stopCount)
    }, 1000)
  },
  computed: {
    formatCountDown() {
      return moment.duration(this.reSendCountDown, 'seconds').format('mm:ss')
    }
  }, 
  methods: {
    async onFinish (rsp) {
      this.loading = true
      try {
        const response = await CreditServices.VerifyOtp({
          memberNo: this.memberNo,
          mobileNo: this.mobileNo,
          storeID: this.$store.state.creditBranch,
          amount: this.amountAvailed,
          otp: rsp
        })
        console.log(response.data)
        if (response.data.success) {
          setTimeout(() => {
            this.loading = false
            this.snackbarColor = 'primary'
            this.message = response.data.message
            this.snackbar = true
          },3500)
          setTimeout(() => {
            this.$emit('set-reference-no', {
              refNo: response.data.refNo,
              memberNo: this.memberNo,
              amount: response.data.amount
            })
          }, 4500)
        } else {
          setTimeout(() => {
            this.loading = false
            this.snackbarColor = 'danger'
            this.message = response.data.message
            this.snackbar = true
          },3500)
        }
      } catch (error) {
        console.log(error)
      }
      
    },
    closeOTPView() {
      this.$emit('closeView', false)
    },
    async reSendOtp() {
      try {
        const response = await CreditServices.SendOtp({
          number: this.mobileNo,
          memberNo: this.memberNo
        })
        console.log(response)
        if (response.data.success) {
          this.reSendCountDown = 300
          const stopCount = setInterval(() => {
            this.reSendCountDown -= 1
            if (this.reSendCountDown == 0) clearInterval(stopCount)
          }, 1000)
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped>
 .position-relative {
   position: relative;
 }
</style>